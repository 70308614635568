.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Position and sizing of burger button */
/* .bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
} */

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  color: black;
}


/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #fff;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: black;
  padding: 0.5em;
  margin: 0.5em;
  font-size: 0.9em;
  cursor: pointer;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.0) !important;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

/* timeline */
.timeline {
  width: 100%;
  background: #fff;
  padding: 100px 50px;
  position: relative;
  box-shadow: 0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2);
}
.timeline:before {
  content: "";
  position: absolute;
  top: 0px;
  left: calc(33% + 15px);
  bottom: 0px;
  width: 4px;
  background: #ddd;
}
.timeline:after {
  content: "";
  display: table;
  clear: both;
}

.entry {
  clear: both;
  text-align: left;
  position: relative;
  margin-bottom: 10px;
}
.entry .title {
  margin-bottom: 0.5em;
  float: left;
  width: 33%;
  padding-right: 30px;
  text-align: right;
  position: relative;
}
.entry .title:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border: 4px solid #582a72;
  background-color: #fff;
  border-radius: 100%;
  top: 15%;
  right: -4px;
  z-index: 99;
}
.entry .title h3 {
  margin: 0;
  font-size: 120%;
}
.entry .title p {
  margin: 0;
  font-size: 100%;
}
.entry .body {
  margin: 0 0 3em;
  float: right;
  width: 66%;
  padding-left: 30px;
}
.entry .body p {
  line-height: 1.4em;
}
.entry .body p:first-child {
  margin-top: 0;
  font-weight: 400;
}
.entry .body ul {
  color: #aaa;
  padding-left: 0;
  list-style-type: none;
}
.entry .body ul li:before {
  content: "–";
  margin-right: 0.5em;
}
